import React from 'react';

import AddFolderModal from './AddRssFolderModal';
import AppStoreQRsModal from './AppStoreQRsModal';
import ExportTemplateModal from './ExportTemplateModal';
import LibraryLocationsModal from './LibraryLocationsModal';

export default function ModalsContainer() {
  return (
    <>
      <LibraryLocationsModal />
      <AppStoreQRsModal />
      <ExportTemplateModal />
      <AddFolderModal />
    </>
  );
}
