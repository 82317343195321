import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  runDocumentLocationsUpdateActions,
  updateDocumentLocationsSetting,
} from '../../../../shared/foreground/actions/documentLocations';
import { hideModal } from '../../../../shared/foreground/actions/modals';
import getUIFriendlyNameForDocumentLocation from '../../../../shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from '../../../../shared/foreground/utils/useDocumentLocations';
import validDocumentLocationsSettingValues from '../../../../shared/foreground/validDocumentLocationsSettingValues';
import { DocumentLocation, SettingsState } from '../../../../shared/types';
import { shortListTag } from '../../../../shared/utils/filteredViews';
import pluralize from '../../../../shared/utils/pluralize';
import documentLocationIconMap from '../../utils/documentLocationIconMap';
import useIsModalShown from '../../utils/useIsModalShown';
import Button from '../Button';
import ChangeDocumentLocationsConfirmationDialog from '../ChangeDocumentLocationsConfirmationDialog';
import RadioGroup from '../RadioGroup';
import styles from './LibraryLocationsModal.module.css';
import Modal from './Modal';

const convertSettingValueToFormValue = (settingValue: SettingsState['documentLocations']) =>
  settingValue.join(',');

const id = 'library-locations';

export default function LibraryLocationsModal() {
  const history = useHistory();
  const isShown = useIsModalShown(id);
  const hide = useCallback(() => hideModal({ id }, { userInteraction: 'click' }), []);

  const documentLocations = useDocumentLocations();
  const currentValueFromState = useMemo(
    () => convertSettingValueToFormValue(documentLocations),
    [documentLocations],
  );
  const [value, setValue] = useState<string>();
  useEffect(() => setValue(currentValueFromState), [currentValueFromState, isShown]);

  const items = useMemo(() => {
    return validDocumentLocationsSettingValues.map((validDocumentLocationsSettingValue) => {
      const locations = validDocumentLocationsSettingValue.filter(
        (docLocation) => docLocation !== DocumentLocation.Feed,
      );

      const locationListItems = locations.map((locationText) => {
        const Icon = documentLocationIconMap[locationText];
        return (
          <li className={styles.location} key={locationText}>
            <Icon text="" /> {getUIFriendlyNameForDocumentLocation(locationText)}
          </li>
        );
      });

      let descriptionContents: JSX.Element;
      if (locations.includes(DocumentLocation.New)) {
        descriptionContents = (
          <>
            <strong>Triage</strong>: Saved documents go to{' '}
            {getUIFriendlyNameForDocumentLocation(DocumentLocation.New)}. From there, triage items you
            want to defer into {getUIFriendlyNameForDocumentLocation(DocumentLocation.Later)}.
          </>
        );
      } else if (locations.includes(DocumentLocation.Shortlist)) {
        descriptionContents = (
          <>
            <strong>Shortlist</strong>: Saved documents go to{' '}
            {getUIFriendlyNameForDocumentLocation(DocumentLocation.Later)}. From there, promote documents
            you want to focus on into {getUIFriendlyNameForDocumentLocation(DocumentLocation.Shortlist)}.
          </>
        );
      } else {
        descriptionContents = (
          <>
            <strong>Classic</strong>: Nothing fancy. Classic read-it-later Instapaper or Pocket workflow.
          </>
        );
      }

      const itemValue = convertSettingValueToFormValue(locations);
      const classes = [styles.radioGroupItemContent];
      if (value === itemValue) {
        classes.push(styles.radioGroupItemContentWhenSelected);
      }

      return {
        content: (
          <div className={classes.join(' ')}>
            <ol className={styles.locations}>{locationListItems}</ol>
            <p className={styles.description}>{descriptionContents}</p>
          </div>
        ),
        value: itemValue,
      };
    });
  }, [value]);

  const mainContent = (
    <>
      <p className={styles.formIntro}>Choose your desired workflow:</p>
      <form className={styles.radioGroups}>
        <RadioGroup
          items={items}
          rootProps={{
            onValueChange: setValue,
            required: true,
            value,
          }}
        />
      </form>
    </>
  );

  const [isConfirmationDialogShown, setIsConfirmationDialogShown] = useState(false);
  const showConfirmationDialog = useCallback(() => setIsConfirmationDialogShown(true), []);
  const hideConfirmationDialog = useCallback(() => setIsConfirmationDialogShown(false), []);
  useEffect(hideConfirmationDialog, [hideConfirmationDialog, isShown]);

  const [confirmationDetails, setConfirmationDetails] = useState<{
    documentLocationInTitle: DocumentLocation;
    subtitle: string;
  } | null>(null);
  useEffect(() => {
    (async () => {
      if (!value || value === currentValueFromState) {
        setConfirmationDetails(null);
        return;
      }

      const newDocumentLocations = [DocumentLocation.Feed, ...(value.split(',') as DocumentLocation[])];

      const updatesThatWouldBeDone = await runDocumentLocationsUpdateActions({
        newDocumentLocations,
        isDryRun: true, // https://www.youtube.com/watch?v=uvsPzuriDdA
        options: { userInteraction: null },
      });

      let documentLocationInTitle: DocumentLocation | undefined;
      const confirmationSubtitles: string[] = [];

      const simpleMoveUpdates = updatesThatWouldBeDone.filter(
        (description) => description.kind === 'move-from-document-location-to-document-location',
      );

      if (simpleMoveUpdates.length && simpleMoveUpdates[0].count) {
        if (!simpleMoveUpdates[0].fromDocumentLocation) {
          throw new Error("fromDocumentLocation should exist but it doesn't");
        }
        if (!simpleMoveUpdates[0].toDocumentLocation) {
          throw new Error("toDocumentLocation should exist but it doesn't");
        }

        documentLocationInTitle = simpleMoveUpdates[0].toDocumentLocation;
        confirmationSubtitles.push(
          `${simpleMoveUpdates[0].count} ${pluralize(
            'document',
            simpleMoveUpdates[0].count,
          )} will move from ${getUIFriendlyNameForDocumentLocation(
            simpleMoveUpdates[0].fromDocumentLocation,
          )} to ${getUIFriendlyNameForDocumentLocation(simpleMoveUpdates[0].toDocumentLocation)}.`,
        );
      }

      const moveFromShortlistUpdates = updatesThatWouldBeDone.find(
        (description) => description.kind === 'move-from-shortlist-location-and-add-tag',
      );
      if (moveFromShortlistUpdates?.count) {
        if (!documentLocationInTitle) {
          documentLocationInTitle = DocumentLocation.Later;
        }
        confirmationSubtitles.push(
          `${moveFromShortlistUpdates.count} ${pluralize(
            'document',
            moveFromShortlistUpdates.count,
          )} will move from ${getUIFriendlyNameForDocumentLocation(
            DocumentLocation.Shortlist,
          )} to ${getUIFriendlyNameForDocumentLocation(DocumentLocation.Later)}.`,
          `A "${shortListTag}" tag will be applied to the moved documents.`,
        );
      }

      if (
        updatesThatWouldBeDone.find(
          (description) => description.kind === 'move-to-shortlist-location-and-remove-tag',
        )?.count
      ) {
        if (!documentLocationInTitle) {
          documentLocationInTitle = DocumentLocation.Shortlist;
        }
        confirmationSubtitles.push(
          `Documents in ${
            DocumentLocation.Later
          } with a "${shortListTag}" tag will move to the ${getUIFriendlyNameForDocumentLocation(
            DocumentLocation.Shortlist,
          )} location.`,
        );
      }

      if (!documentLocationInTitle) {
        setConfirmationDetails(null);
        return;
      }

      setConfirmationDetails({
        documentLocationInTitle,
        subtitle: confirmationSubtitles.join(' '),
      });
    })();
  }, [currentValueFromState, documentLocations, value]);

  const onConfirm = useCallback(() => {
    if (value) {
      updateDocumentLocationsSetting(
        [DocumentLocation.Feed, ...(value.split(',') as DocumentLocation[])],
        { userInteraction: 'click' },
      );
      history.push('/library');
    }
    hide();
  }, [hide, history, value]);

  const onClickUpdate = useCallback(() => {
    if (confirmationDetails) {
      showConfirmationDialog();
      return;
    }
    onConfirm();
  }, [confirmationDetails, onConfirm, showConfirmationDialog]);

  const footerContent = (
    <>
      <Button className={styles.cancelButton} onClick={hide} variant="secondary">
        Cancel
      </Button>
      <Button onClick={onClickUpdate} variant="primary">
        Update
      </Button>
    </>
  );

  let confirmation: JSX.Element | null = null;
  // Will it need a confirmation?
  if (confirmationDetails) {
    confirmation = (
      <ChangeDocumentLocationsConfirmationDialog
        documentLocationInTitle={confirmationDetails.documentLocationInTitle}
        isShown={isConfirmationDialogShown}
        onCancel={hideConfirmationDialog}
        onConfirm={onConfirm}
        subtitle={confirmationDetails.subtitle}
      />
    );
  }

  return (
    <>
      <Modal id={id} footerContent={footerContent} mainContent={mainContent} title="Library locations" />
      {confirmation}
    </>
  );
}
